import { SegmentBase } from "./segment-base";

type ResponseCookies = Awaited<
  ReturnType<typeof import("next/headers").cookies>
>;

export class SegmentServerClient extends SegmentBase {
  private segmentCreateAnonymousIdUrl: string;

  constructor(apiUrl: string, writeKey: string) {
    super(apiUrl, writeKey);
    this.segmentCreateAnonymousIdUrl = process.env["SEGMENT_API_URL"] + "/rand";
  }

  protected setCookieDomain(): void {
    this.cookieDomain =
      process.env["DEPLOYMENT_ENVIRONMENT"] === "yardzen-staging"
        ? ".staging.yardzen.com"
        : process.env["DEPLOYMENT_ENVIRONMENT"] === "development"
          ? "localhost"
          : ".yardzen.com";
  }

  protected async findAnonymousId() {
    if (this.anonymousId) {
      return this.anonymousId;
    }

    let serverCookies: ResponseCookies | undefined;

    if (typeof Headers !== "undefined") {
      try {
        const { cookies } = await import("next/headers");
        serverCookies = await cookies();
        const cookie = serverCookies.get("yzsa");
        console.log("yzsa from headers: ", cookie?.value);
        if (cookie?.value) {
          this.setAnonymousId(cookie.value);
          return cookie.value;
        }
      } catch (e) {
        console.debug("Not in Next.js app router context");
      }
    }

    return await this.createAnonymousId(serverCookies);
  }

  private async createAnonymousId(serverCookies: ResponseCookies | undefined) {
    console.log("Creating anonymous id");
    const yzsa = await fetch(this.segmentCreateAnonymousIdUrl)
      .then((r) => r.text())
      .catch((err) => {
        console.error(err);
        return undefined;
      });
    console.log("Created anonymous id:", yzsa);

    if (yzsa) {
      this.setAnonymousId(yzsa);
      this.createCookie(yzsa, serverCookies);
      return yzsa;
    }

    console.error("Unable to find or set anonymousId");
    return undefined;
  }

  protected createCookie(
    yzsa: string,
    serverCookies: ResponseCookies | undefined,
  ) {
    this.setCookieDomain();
    console.log(
      "Setting cookie in server for domain: ",
      this.cookieDomain,
      "with value: ",
      yzsa,
    );
    try {
      serverCookies?.set("yzsa", yzsa, {
        path: "/",
        maxAge: 31536000,
        domain: this.cookieDomain || undefined,
      });
    } catch (e) {
      console.debug("Unable to set cookie in server context", e);
    }
  }

  protected getBrowserInfo() {
    return {};
  }
}
