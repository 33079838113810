/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseClient } from "./client.generated";

export class SegmentBrowserClient extends BaseClient {
  private readonly maxRetries = 10;
  private userId: string | null = null;
  public anonymousId: string | null = null;
  private segmentCreateAnonymousIdUrl = "/api/s/rand";
  private cookieDomain: "localhost" | ".staging.yardzen.com" | ".yardzen.com" =
    ".yardzen.com";
  private writeKey: string | null = null;
  private apiUrl: string | null = null;

  public constructor() {
    super();
  }

  public setWriteKey(key: string): void {
    this.writeKey = key;
  }

  public setApiUrl(url: string): void {
    this.apiUrl = url;
  }

  public setUserId(userId: string | null): void {
    this.userId = userId;
  }

  public setAnonymousId(anonymousId: string | null): void {
    console.log("Setting anonymousId", anonymousId);
    this.anonymousId = anonymousId;
  }

  public setSegmentCreateAnonymousIdUrl(): void {
    this.segmentCreateAnonymousIdUrl = "/api/s/rand";
  }

  public setCookieDomain(): void {
    const url = new URL(window.location.href);

    if (url.hostname === "localhost") {
      this.cookieDomain = "localhost";
    } else if (url.hostname.includes("staging")) {
      this.cookieDomain = ".staging.yardzen.com";
    } else {
      this.cookieDomain = ".yardzen.com";
    }
  }

  private async findAnonymousId() {
    if (this.anonymousId) {
      return this.anonymousId;
    }

    const yzsaCookie = document.cookie.match(/yzsa=([^;]+)/)?.[1];
    if (yzsaCookie) {
      this.setAnonymousId(yzsaCookie);
      return yzsaCookie;
    }

    return await this.createAnonymousId();
  }

  private createAnonymousId = async () => {
    this.setSegmentCreateAnonymousIdUrl();
    console.log("Creating anonymous id");
    const yzsa = await fetch(this.segmentCreateAnonymousIdUrl)
      .then((r) => r.text())
      .catch((err) => {
        console.error(err);
        return undefined;
      });
    console.log("Created anonymous id:", yzsa);

    if (yzsa) {
      this.setAnonymousId(yzsa);
      this.createCookie(yzsa);
      return yzsa;
    }

    console.error("Unable to find or set anonymousId");
    return undefined;
  };

  private createCookie = (yzsa: string) => {
    let c = `yzsa=${yzsa};path=/;max-age=31536000`;
    this.setCookieDomain();
    c += `;domain=${this.cookieDomain}`;
    console.log("Setting cookie in browser for domain:", this.cookieDomain);
    document.cookie = c;
  };

  private send = async (
    method: "identify" | "track" | "page",
    message: Record<string, any>,
    callback: ((err: Error) => void) | undefined,
    context = this.getBrowserInfo(),
    retries = 0,
  ): Promise<void> => {
    if (!this.writeKey) {
      const error = new Error("Segment WriteKey is required");
      callback?.(error);
      return;
    }

    if (!("userId" in message) && this.userId) {
      message["userId"] = this.userId;
    }

    const anonymousId = !("anonymousId" in message)
      ? await this.findAnonymousId().catch((err) => {
          console.error(err);
          return undefined;
        })
      : message["anonymousId"];

    if (!message["userId"] && !anonymousId) {
      const error = new Error(
        "Either userId or anonymousId is required to send to Segment",
      );
      callback?.(error);
      return;
    }

    if (anonymousId) {
      message["anonymousId"] = anonymousId;
    }

    const url = this.apiUrl + "/v2/" + method;
    const body = JSON.stringify({
      ...message,
      writeKey: this.writeKey,
      context: {
        ...context,
        ...message["context"],
      },
      ...(method === "page"
        ? { properties: { ...message["properties"], ...context.page } }
        : { properties: { ...message["properties"] } }),
    });

    if (typeof navigator != "undefined" && !!navigator["sendBeacon"]) {
      await this.sendBeacon(url, body);
      return callback?.(undefined as any);
    }

    return await this.sendFetch(url, body, callback, retries);
  };

  private getBrowserInfo() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      referrer: {
        type: document.referrer ? "internal" : "external",
        url: document.referrer,
        name: document.referrer,
        link: document.referrer,
      },
      page: {
        path: window.location.pathname,
        title: document.title,
        url: window.location.href,
        referrer: document.referrer,
        name: document.referrer,
        search: window.location.search,
        hash: window.location.hash,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      campaign: {
        source: searchParams.get("utm_source"),
        medium: searchParams.get("utm_medium"),
        term: searchParams.get("utm_term"),
        content: searchParams.get("utm_content"),
        campaign: searchParams.get("utm_campaign"),
      },
    };
  }

  public override async asyncTrack(
    ...args: Parameters<BaseClient["asyncTrack"]>
  ) {
    return super.asyncTrack(...args).catch((err) => {
      console.error(err);
      return err;
    });
  }

  public override async typedIdentify(
    ...args: Parameters<BaseClient["typedIdentify"]>
  ) {
    return super.typedIdentify(...args).catch((err) => {
      console.error(err);
      return err;
    });
  }

  public override identify(
    message: (
      | { userId: string | number }
      | { anonymousId: string | number }
    ) & {
      traits?: any;
      timestamp?: Date | undefined;
      context?: any;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("identify", message, callback);
  }

  public override track(
    message: (
      | { userId: string | number }
      | { anonymousId: string | number }
    ) & {
      event: string;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("track", message, callback);
  }

  public override page(
    message: (
      | { userId?: string | number }
      | { anonymousId?: string | number }
    ) & {
      category?: string | undefined;
      name?: string | undefined;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
      messageId?: string | undefined;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("page", message, callback);
  }

  private async sendBeacon(url: string, body: string) {
    const blob = new Blob([body], { type: "text/plain" });
    return navigator.sendBeacon(url, blob);
  }

  private async sendFetch(
    url: string,
    body: string,
    callback?: (err: Error) => void,
    retries = 0,
  ): Promise<void> {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      });

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
    } catch (error) {
      if (retries < this.maxRetries) {
        return this.sendFetch(url, body, callback, retries + 1);
      }
      callback?.(error as Error);
    }
  }
}
