// (this as any) file is autogenerated, please do not edit
/* tslint:disable */

import { Expose } from "class-transformer";
import {
  IsArray,
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  Matches,
  validateOrReject,
} from "class-validator";
import "reflect-metadata";

type Identity =
  | {
      userId: string | number;
    }
  | {
      anonymousId: string | number;
    };

type Message = Identity & {
  type: string;
  context: {
    library: {
      name: string;
      version: string;
    };
    [key: string]: any;
  };
  _metadata: {
    nodeVersion: string;
    [key: string]: any;
  };
  timestamp?: Date | undefined;
  messageId?: string | undefined;
};

interface Data {
  batch: Message[];
  timestamp: Date;
  sentAt: Date;
}

interface Integrations {
  [integration_name: string]: IntegrationValue;
}

type IntegrationValue = boolean | { [integration_key: string]: any };

export class IdentifyTraits {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public city?: string; // string
  @Expose()
  @IsBoolean()
  @IsOptional()
  public client_requested_contractor?: boolean; // boolean
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public cordial_id?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public country?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public county?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public email?: string; // string - Client email address
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public fbc?: string; // string - Facebook click id
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public fbp?: string; // string - Facebook browser id
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public first_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public last_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public package?: string; // string
  @Expose()
  @Matches("^[0-9]{10,11}$")
  @IsOptional()
  public phone?: string; // string
  @Expose()
  @IsBoolean()
  @IsOptional()
  public purchased?: boolean; // boolean
  @Expose()
  @IsObject()
  @IsOptional()
  public split_flags?: { [key: string]: any }; // object
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public state?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public street_address?: string; // string
  @Expose()
  @IsNumber()
  @IsOptional()
  public v1_dsat_score?: number; // number
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public v1_sharelink?: string; // string
  @Expose()
  @IsNumber()
  @IsOptional()
  public v2_dsat_score?: number; // number
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public v2_sharelink?: string; // string
  @Expose()
  @IsNumber()
  @IsOptional()
  public v3_dsat_score?: number; // number
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public wootric_nps_date?: string; // string
  @Expose()
  @IsNumber()
  @IsOptional()
  public wootric_nps_score?: number; // number
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public yardzen_anonymous_id?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public zip_code?: string; // string
}

export class AccountCreatedEvent {}

export class AddedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public addable_name!: string; // string - list that you are adding to
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public item_name?: string; // string - item removed
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class ClickedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public button_content?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public button_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public click_type!: string; // string - e.g. button, footer, nav...
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_category?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_id?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_vendor?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
  @Expose()
  @IsNumber()
  @IsOptional()
  public value?: number; // number
}

export class ConversionEvent {
  @Expose()
  @IsNumber()
  @IsOptional()
  public amount?: number; // number
  @Expose()
  @IsBoolean()
  public client_reported!: boolean; // boolean
  @Expose()
  @IsString()
  @IsNotEmpty()
  public conversion_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_category?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_details?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_id?: string; // string
  @Expose()
  @IsArray()
  @IsOptional()
  public product_ids?: any[]; // array,null
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public product_vendor?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class ExperimentEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public experiment!: string; // string - name of experiment
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public flow_name?: string; // string
  @Expose()
  @IsBoolean()
  public seen!: boolean; // boolean - whether or not experiment was seen
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public start_action?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class FlowCompletedEvent {
  @Expose()
  @IsOptional()
  public data?: any; //
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
}

export class FlowStartedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
}

export class FormFieldFilledEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public field_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public flow_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public form_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public input_type?: string; // string - text, select, radio group, etc.
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public option_selected?: string; // string,null - select/radio button selection
  @Expose()
  @IsArray()
  @IsOptional()
  public options_selected?: any[]; // array,null - multiple select selection
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public text_value?: string; // string - value from text input
}

export class FormSubmittedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public form_name!: string; // string
  @Expose()
  @IsBoolean()
  @IsOptional()
  public passed_validation?: boolean; // boolean
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class MessageSentEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public conversation_id?: string; // string
}

export class PageVariantEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public variant_description?: string; // string - Description of the Variant being tracked
  @Expose()
  @IsString()
  @IsNotEmpty()
  public variant_name!: string; // string - Name of the Variant being tracked
}

export class RemovedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public item_name?: string; // string - item removed
  @Expose()
  @IsString()
  @IsNotEmpty()
  public removable_name!: string; // string - list that you are removing from
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class ScrolledEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public content_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
}

export class SignInEvent {}

export class SignOutEvent {}

export class SubFlowCompletedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public completion_action?: string; // string
  @Expose()
  @IsOptional()
  public data?: any; //
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public sub_flow_name!: string; // string
}

export class SubFlowStartedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public start_action?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public sub_flow_name!: string; // string
}

export class UploadedEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public comment?: string; // string
  @Expose()
  @IsBoolean()
  @IsOptional()
  public comment_required?: boolean; // boolean
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public file_name?: string; // string
  @Expose()
  @IsNumber()
  @IsInt()
  @IsOptional()
  public file_size?: number; // integer
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public file_type?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public prompt?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public upload_name!: string; // string
  @Expose()
  @IsNumber()
  @IsInt()
  @IsOptional()
  public upload_time?: number; // integer - milliseconds please
}

export class VideoActionEvent {
  @Expose()
  @IsString()
  @IsNotEmpty()
  public action!: string; // string - play, pause...
  @Expose()
  @IsString()
  @IsNotEmpty()
  public flow_name!: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public sub_flow_name?: string; // string
  @Expose()
  @IsString()
  @IsNotEmpty()
  public video_name!: string; // string
}

export abstract class BaseClient {
  public async typedIdentify(
    traits: IdentifyTraits & Record<string, unknown>,
    opts?: { id?: string; anonymousId?: string },
  ): Promise<void> {
    const body: { [key: string]: any } = {
      traits,
      ...opts,
    };

    return new Promise<void>((resolve, reject) => {
      (this as any).identify(body as any, (err: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  /** Client sets a password */
  public async trackAccountCreated(
    properties: AccountCreatedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Account Created", properties, opts);
  }

  /** Generic add item to list */
  public async trackAdded(
    properties: AddedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Added", properties, opts);
  }

  public async trackClicked(
    properties: ClickedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Clicked", properties, opts);
  }

  public async trackConversion(
    properties: ConversionEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Conversion", properties, opts);
  }

  /** Tracking a/b tests */
  public async trackExperiment(
    properties: ExperimentEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Experiment", properties, opts);
  }

  public async trackFlowCompleted(
    properties: FlowCompletedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Flow Completed", properties, opts);
  }

  public async trackFlowStarted(
    properties: FlowStartedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Flow Started", properties, opts);
  }

  public async trackFormFieldFilled(
    properties: FormFieldFilledEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Form Field Filled", properties, opts);
  }

  public async trackFormSubmitted(
    properties: FormSubmittedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Form Submitted", properties, opts);
  }

  /** Track messages sent between pros/clients/yardzen. */
  public async trackMessageSent(
    properties: MessageSentEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Message Sent", properties, opts);
  }

  /** Track when a user sees a variant of a given screen */
  public async trackPageVariant(
    properties: PageVariantEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Page Variant", properties, opts);
  }

  /** Generic remove item from list */
  public async trackRemoved(
    properties: RemovedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Removed", properties, opts);
  }

  /** Content Visible */
  public async trackScrolled(
    properties: ScrolledEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Scrolled", properties, opts);
  }

  /** Client logs in */
  public async trackSignIn(
    properties: SignInEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Sign In", properties, opts);
  }

  /** Client logs out */
  public async trackSignOut(
    properties: SignOutEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Sign Out", properties, opts);
  }

  public async trackSubFlowCompleted(
    properties: SubFlowCompletedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Sub Flow Completed", properties, opts);
  }

  public async trackSubFlowStarted(
    properties: SubFlowStartedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Sub Flow Started", properties, opts);
  }

  public async trackUploaded(
    properties: UploadedEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Uploaded", properties, opts);
  }

  public async trackVideoAction(
    properties: VideoActionEvent,
    opts?: { id?: string; anonymousId?: string },
  ) {
    await validateOrReject(properties);
    await (this as any).asyncTrack("Video Action", properties, opts);
  }

  public async asyncTrack(
    eventName: string,
    properties: any,
    opts?: { id?: string; anonymousId?: string },
  ) {
    return new Promise<void>((resolve: any, reject: any) => {
      const body: { [key: string]: any } = {
        event: eventName,
        properties,
        ...opts,
      };

      (this as any).track(body as any, (err: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  protected identify(
    message: Identity & {
      traits?: any;
      timestamp?: Date | undefined;
      context?: any;
      integrations?: Integrations | undefined;
    },
    callback?: (err: Error) => void,
  ): void {
    return;
  }

  protected track(
    message: Identity & {
      event: string;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
      integrations?: Integrations | undefined;
    },
    callback?: (err: Error) => void,
  ): void {
    return;
  }

  protected abstract page(
    message: Identity & {
      category?: string | undefined;
      name?: string | undefined;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
      integrations?: Integrations | undefined;
      messageId?: string | undefined;
    },
    callback?: (err: Error) => void,
  ): void;
}
