import { SegmentBrowserClient, SegmentServerClient } from "@yardzen/segmentv2";

export const createServerAnalytics = () => {
  return new SegmentServerClient(
    process.env.SEGMENT_API_URL ?? "",
    process.env.SEGMENT_WRITE_KEY ?? "",
  );
};

const isServer = () => {
  return typeof window === "undefined";
};

const createAnalytics = () => {
  if (isServer()) {
    console.error(
      "Direct usage of analytics singleton is not supported server-side. Use createServerAnalytics() to create a new instance for each request.",
    );
  }

  //Client side calls are getting the api and key set through the ClientSegmentProvider
  return new SegmentBrowserClient();
};

export const analytics = createAnalytics();
