/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { BaseClient } from "./client.generated";

export abstract class SegmentBase extends BaseClient {
  protected readonly maxRetries = 10;
  protected userId: string | null = null;
  public anonymousId: string | null = null;
  protected cookieDomain:
    | "localhost"
    | ".staging.yardzen.com"
    | ".yardzen.com" = ".yardzen.com";

  constructor(
    protected apiUrl: string,
    protected writeKey: string,
  ) {
    super();
  }

  public setUserId(userId: string | null): void {
    this.userId = userId;
  }

  public setAnonymousId(anonymousId: string | null): void {
    console.log("Setting anonymousId", anonymousId);
    this.anonymousId = anonymousId;
  }

  public setWriteKey(key: string): void {
    this.writeKey = key;
  }

  public setApiUrl(url: string): void {
    this.apiUrl = url;
  }

  // Abstract methods that must be implemented by client/server
  protected abstract findAnonymousId(): Promise<string | undefined>;
  protected abstract createCookie(yzsa: string, ...args: any[]): void;
  protected abstract setCookieDomain(): void;
  protected abstract getBrowserInfo(): Record<string, any>;

  protected async sendFetch(
    url: string,
    body: string,
    callback: ((err: Error) => void) | undefined,
    retries: number,
  ): Promise<void> {
    try {
      console.log("Sending fetch to segment: ", url);
      await axios.post(url, body);
    } catch (err) {
      retries++;
      if (retries >= this.maxRetries) {
        console.error(
          "Retry limit exceeded trying to send message to cdp",
          url,
          err,
        );
        return callback?.(err as Error);
      }

      await new Promise((resolve) => setTimeout(resolve, 5 ** retries));
      return this.sendFetch(url, body, callback, retries);
    }
  }

  protected send(
    method: "identify" | "track" | "page",
    message: Record<string, any>,
    callback: ((err: Error) => void) | undefined,
    context = this.getBrowserInfo(),
    retries = 0,
  ): void {
    // Do not block the main thread
    (async () => {
      try {
        if (!this.writeKey) {
          throw new Error("Segment WriteKey is required");
        }

        if (!("userId" in message) && this.userId) {
          message["userId"] = this.userId;
        }

        const anonymousId = !("anonymousId" in message)
          ? await this.findAnonymousId().catch((err) => {
              console.error(err);
              return undefined;
            })
          : message["anonymousId"];

        if (!message["userId"] && !anonymousId) {
          throw new Error(
            "Either userId or anonymousId is required to send to Segment",
          );
        }

        if (anonymousId) {
          message["anonymousId"] = anonymousId;
        }

        const url = this.apiUrl + "/v2/" + method;
        const body = JSON.stringify({
          ...message,
          writeKey: this.writeKey,
          context: {
            ...context,
            ...message["context"],
          },
          ...(method === "page"
            ? { properties: { ...message["properties"], ...context["page"] } }
            : { properties: { ...message["properties"] } }),
        });

        this.sendFetch(url, body, callback, retries).catch((err) => {
          console.error("Failed to send analytics:", err);
          callback?.(err);
        });
      } catch (error) {
        console.error("Error preparing analytics:", error);
        callback?.(error as Error);
      }
    })().catch((err) => {
      console.error("Unexpected analytics error:", err);
      callback?.(err);
    });
  }

  // Common interface methods
  public override identify(
    message: (
      | { userId: string | number }
      | { anonymousId: string | number }
    ) & {
      traits?: any;
      timestamp?: Date | undefined;
      context?: any;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("identify", message, callback);
  }

  public override track(
    message: (
      | { userId: string | number }
      | { anonymousId: string | number }
    ) & {
      event: string;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("track", message, callback);
  }

  public override page(
    message: (
      | { userId?: string | number }
      | { anonymousId?: string | number }
    ) & {
      category?: string | undefined;
      name?: string | undefined;
      properties?: any;
      timestamp?: Date | undefined;
      context?: any;
      messageId?: string | undefined;
    },
    callback?: ((err: Error) => void) | undefined,
  ): void {
    this.send("page", message, callback);
  }

  public override async asyncTrack(
    ...args: Parameters<BaseClient["asyncTrack"]>
  ) {
    (async () => {
      try {
        await super.asyncTrack(...args);
      } catch (err) {
        console.error("Analytics tracking error:", err);
      }
    })().catch((err) => {
      console.error("Unexpected analytics error:", err);
    });

    // Return immediately so it doesn't block the main thread
    return Promise.resolve();
  }

  public override async typedIdentify(
    ...args: Parameters<BaseClient["typedIdentify"]>
  ) {
    return super.typedIdentify(...args).catch((err) => {
      console.error(err);
      return err;
    });
  }
}
